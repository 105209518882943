import { InfoVideoStore } from "./InfoVideoStore";
import { RootStore } from '../../stores/RootStore';
import React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Button, Checkbox, Confirm, Grid, Icon, Image, Radio } from 'semantic-ui-react';
import InfoVideoDetailsPage from './InfoVideoDetailsPage'
import logo from '../../assets/logo.png';
import video1 from '../../assets/01Infovideo.mp4'
import video13 from '../../assets/0304Szerzodeskotes.mp4'
import video16 from '../../assets/05Roaming.mp4'
import video11 from '../../assets/06Szerzodesmasolat.mp4'
import video5 from '../../assets/07Szerzodesmodositas.mp4'
import video18 from '../../assets/08Felmondasijog.mp4'
import video4 from '../../assets/09Adatvedelmitajekoztato.mp4'
import video10 from '../../assets/10Hivasreszletezo.mp4'
import video21 from '../../assets/11Egyediertekhatar.mp4'
import video14 from '../../assets/14Szerzodesidotartama.mp4'
import video15 from '../../assets/15Hivasreszlezeto.mp4'
import video3 from '../../assets/16Direktmarketing.mp4'
import video2 from '../../assets/17Helymeghatarozas.mp4'
import picture7 from '../../assets/7_Elallas.jpeg'
import picture9 from '../../assets/9_Elektronikus_Szerzodes.jpeg'
import picture10 from '../../assets/10_ESZSZF.jpeg'
import picture12 from '../../assets/12_KEKKH.jpg'
// import picture14 from '../../assets/14_Nyilatkozat_inflacio.jpeg'
import picture15 from '../../assets/15_Nyilatkozat_jogkepesseg.jpeg'
import picture17 from '../../assets/17_Szerzodes_tartalma.jpeg'
import picture20 from '../../assets/20_Ketoldalu_modositas.jpeg'
import picture1 from '../../assets/1_E-szamla.jpeg'
import picture6 from '../../assets/6_Egyeni_elofizeto.jpeg'
import picture8 from '../../assets/8_Elektronikus_ertesites.jpg'
import picture18 from '../../assets/18_ASZF.jpeg'
import picture21 from '../../assets/7_Elallas.jpeg'



interface IStores {
    InfoVideoStore: InfoVideoStore;
}

interface IInfoVideoPageProps {
    RootStore?: RootStore;
}

class InfoVideoPage extends React.Component<IInfoVideoPageProps> {
    private stores: IStores;

    constructor(props: any) {
        super(props);

        this.stores = { InfoVideoStore: new InfoVideoStore(this.props.RootStore.offerId, this.props.RootStore) }
    }

    public render() {
        return (
            <div>
                <Provider {...this.stores}>
                    {!this.stores.InfoVideoStore.offerExist ? <InfoVideoDetailsPage titleString={"Nem található az ajánlat"} />
                        :
                        <div>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <div style={{ alignItems: 'center' }}>
                                            <Image src={logo} size="medium" style={{ marginBottom: '20px' }} />
                                        </div>
                                        {this.stores.InfoVideoStore.contentItems.map((item, index) => {
                                            return (
                                                <div key={item.key} style={{ fontSize: this.stores.InfoVideoStore.RootStore.isMobile ? '12px' : '19px', fontWeight: (index === this.stores.InfoVideoStore.currentNumber) ? 'bold' : '400', marginBottom: this.stores.InfoVideoStore.RootStore.isMobile ? 5 : 20 }}>
                                                    {item.title}
                                                    {item.checked && (
                                                        <Icon color="green" name="check circle outline" style={{ marginLeft: 10 }}></Icon>
                                                    )}
                                                </div>
                                            )
                                        })}
                                        {/* onClick={() => {this.stores.InfoVideoStore.setContentItemActive(index)}} */}
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        {this.stores.InfoVideoStore.currentNumber === 0 && (
                                            <div>
                                                <div style={{ display: 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(1) }}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage videoUrl={video1} titleString={"Infovideó, mint digitális segítség"}
                                                    description={"Tisztelt Ügyfelünk! Köszönjük, hogy figyelemmel kísérted Kollégánk tájékoztatóját! Az előző oldalon összegeztük számodra az elhangzottakat. A következő oldalakon szeretnénk tájékoztatni a szerződés részletes feltételeiről. Célunk, hogy minden szükséges feltételt a legegyszerűbb módon ismerhess meg, ehhez rövid videókkal nyújtunk segítséget. Minden videó egy nyilatkozat megismerését teszi lehetővé, így az egyes videóanyagok megtekintése után minden esetben nyilatkozhatsz döntésedről. Lesznek olyan helyzetek, ahol dönthetsz úgy, hogy igénybe kívánod venni az adott lehetőséget, azonban a jelöléssel ellátott videók esetében ”ELFOGADOM” válasz megadása nélkül a szerződés nem köthető meg. Ha a videók megtekintését követően további kérdésed merülne, ismét megtekintheted azokat. Ha a nyilatkozat elolvasását követően további kérdéseid lennének, fordulj bátran kollégáinkhoz!"}>
                                                    <div>
                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 1 && (

                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(0) }}>Vissza</Button>
                                                    </div>
                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(2) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture1} titleString={"1. E-számla"}
                                                    description={"A Szolgáltató a számlát elektronikus számla bemutatási rendszer útján küldje meg részemre a személyes adataimnál megadott e-mail címemre."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>


                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 2 && (
                                            <React.Fragment>
                                                <div>
                                                    <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                        <div style={{ marginRight: 30 }}>
                                                            <Button onClick={() => { this.stores.InfoVideoStore.previousPage(1) }}>Vissza</Button>
                                                        </div>
                                                        <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                            <Checkbox label="Hozzájárulok" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                        </div>
                                                        <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                            <Checkbox label="Nem járulok hozzá" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>

                                                        </div>
                                                        <div>
                                                            <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(3) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                        </div>
                                                    </div>
                                                    <InfoVideoDetailsPage pictureUrl={picture8} titleString={"2. Geo-lokáció"}
                                                        description={"Hozzájárulok, hogy a One Magyarország Zrt. a forgalmi adatokon kívüli helymeghatározási adataimat (bázisállomás azonosítója, címe, geo-koordináta) értéknövelt szolgáltatás nyújtása céljából telefonon vagy egyéb elektronikus hírközlési úton (pl. sms, mms, e-mail), automatizált hívórendszeren, illetve postai úton felhasználja, mely hozzájárulásom kiterjed a vállalatcsoport más vállalatai és a szolgáltató szerződéses partnerei értéknövelt szolgáltatásokra vonatkozó ajánlatainak közvetítésére is."}>
                                                        <div></div>
                                                    </InfoVideoDetailsPage>

                                                </div>



                                            </React.Fragment>
                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 3 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(2) }}>Vissza</Button>
                                                    </div>
                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Hozzájárulok" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem járulok hozzá" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(4) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture8} titleString={"3. Marketing"}
                                                    description={"Hozzájárulok, hogy a One Magyarország Zrt. a személyes (azonosító, forgalmi, díjfizetési és számlázási) adataimat közvetlen üzletszerzés, személyre szabott ajánlat közvetítése és piackutatás céljából telefonon vagy egyéb elektronikus hírközlési úton (pl. sms, mms, e-mail), automatizált hívórendszeren és internetes ügyfélkiszolgáló rendszeren keresztül, illetve postai úton felhasználja, mely hozzájárulásom kiterjed a vállalatcsoport más vállalatai és a szolgáltató szerződéses partnerei hasonló termékek vagy szolgáltatásokra vonatkozó ajánlatainak közvetítésére."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>


                                            </div>


                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 4 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(3) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>


                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(5) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture8} titleString={"4. Adatkezelési tájékoztató"}
                                                    description={<div>Előfizető kijelentem, hogy a személyes adataim Szolgáltató részére történő átadásának időpontjában a Szolgáltató rendelkezésemre bocsátotta az előfizetői személyes adatok kezeléséről szóló adatkezelési tájékoztatóját, amelyet a One Lakossági Vezetékes ÁSZF (továbbiakban ÁSZF) 3. számú melléklete tartalmaz: https://www.one.hu/adatkezeles (egyetértek, hogy szolgáltatási típusonként eltérő az ÁSZF-re történő hivatkozás (mobil, vezetékes, lakossági, üzleti)).
                                                        Tudomásul veszem, hogy a Szolgáltató jogszabályban meghatározott feltételek mellett jogszabályban meghatározott egyes személyes adataimat megőrizni és továbbítani köteles a bíróság, ügyészség, nyomozó hatóság, nemzetbiztonsági szolgálat, körözési eljárást lefolytató szerv, Magyar Nemzeti Bank, és Gazdasági Versenyhivatal részére. Ezen túlmenően forgalmi és számlázási adataim átadhatók azoknak, akik a Szolgáltató megbízása alapján a számlázást, a forgalmazás kezelését, a követelések kezelését végzik, továbbá a számlázási és forgalmazási jogviták rendezésére jogosult szervek és a végrehajtó részére. Fentieken túl tudomásul veszem, hogy a Szolgáltató szerződésszegésem esetén forgalmi és számlázási adataimat jogszabályban meghatározottak szerint az elektronikus hírközlési szolgáltatók által létrehozható közös adatállományában, az Előfizetői Szerződéssel kapcsolatos díjfizetési, illetőleg a Szerződésből eredő egyéb kötelezettség kijátszásának megelőzése céljából szerepelteti.</div>}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>


                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 5 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(4) }}>Vissza</Button>
                                                    </div>
                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Kérem" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem kérem" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(6) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture8} titleString={"5. E-számla Post"}
                                                    description={"Előfizető nyilatkozom, hogy a Szolgáltatónál fenntartott előfizetésemhez megrendelem az elektronikus számlázást. A Szolgáltató az E-számla igénybevétele alatt az Előfizetői Szerződéssel, az Előfizető által használt szolgáltatásokkal, illetve azok igénybevételével kapcsolatos (jogszabály előírása alapján nem kötelezően tértivevénnyel küldendő) tájékoztató leveleket és értesítéseket, (így például az Előfizető által bejelentett panaszok kivizsgálásának eredményéről szóló, valamint a kiállított számlák teljesítésével kapcsolatos felszólításokat) az Online Ügyfélszolgálat felületén érhetem el."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 6 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(5) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(7) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture6} titleString={"6. Egyéni előfizetői minőséggel kapcsolatos nyilatkozat"}
                                                    description={"Nyilatkozom, hogy a szolgáltatást egyéni előfizetőként kívánom igénybe venni."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>


                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 7 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(6) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(8) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture7} titleString={"7. Elállás / azonnali hatályú felmondás"}
                                                    description={"Kijelentem, hogy az előfizetői szerződésre vonatkozó szerződési nyilatkozatom megtétele előtt az előzetes tájékoztatóban megkaptam a 45/2014. (II.26.) Korm. rendelet szerinti tájékoztatást, így különösen az üzlethelyiségen kívül és a távollevők között kötött szerződés esetén a fogyasztót megillető, indokolás nélküli 14 napos elállási, illetve felmondási jog gyakorlásának lehetőségéről és feltételeiről. A jelenlevők között üzlethelyiségen kívül és a távollévők között megkötött Előfizetői Szerződés esetén az Egyéni Előfizető az Előfizetői Szerződés megkötésétől számított 14 (tizennégy) napon belül hátrányos jogkövetkezmények és indokolás nélkül elállhat az Előfizetői Szerződéstől. Ha a Szolgáltató az Előfizetői Szerződés teljesítését az elállási jog gyakorlására nyitva álló határidőn belül már megkezdte, az Előfizetőt az Előfizetői Szerződés megkötésétől számított 14 napon belül indokolás nélküli, azonnali hatályú felmondási jog illeti meg."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 8 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(7) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(9) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture8} titleString={"8. Elektronikus értesítés és elektronikus szerződés"}
                                                    description={"Kijelentem, hogy elfogadom, hogy a Szolgáltató értesítési kötelezettségének – beleértve a felmondást is – az általam megadott elérhetőségeken az Eht. 144. § (4) bekezdés a)-c) pontjában foglaltaknak megfelelően elektronikus úton vagy az elektronikus hírközlési szolgáltatás jellegéhez igazodó módon (különösen: SMS, MMS, telefonhívás) is eleget tehet. Megegyezésünknek megfelelően kérem, hogy a Szolgáltató az Előfizetői Szerződésemet alkotó dokumentumokat, azaz az előzetes tájékoztatást, az előfizetői szerződés adatainak összefoglalóját, valamint az egyedi előfizetői szerződést az általam megadott e-mail címre elektronikus levélben küldje meg."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 9 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(8) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Igen" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(10) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture9} titleString={"9. Tudakozó"}
                                                    description={"Szeretné, hogy a Tudakozóban megjelenjenek az adatai? Tudomásul veszem, hogy az előfizetői névjegyzék az előfizető önkéntes és egyértelmű hozzájárulása nélkül csak annyi adatot tartalmazhat róla, amennyi azonosításához feltétlenül szükséges. A szolgáltató külön költség nélkül biztosítja minden előfizető számára a jogot, hogy kérésére kimaradjon névjegyzékből, vagy feltüntethesse magát a névjegyzékben nevével, lakcímével (székhelyével) és előfizetői hívószámával. A névjegyzékben meg kívánok jelenni."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                                {/* <InfoVideoDetailsPage pictureUrl={picture9} titleString={"9. Elektronikus szerződés"}
                                                    description={"Megegyezésünknek megfelelően kérem, hogy a Szolgáltató az Előfizetői Szerződésemet alkotó dokumentumokat, azaz az előzetes tájékoztatást, az előfizetői szerződés adatainak összefoglalóját, valamint az egyedi előfizetői szerződést az általam megadott e-mail címre elektronikus levélben küldje meg."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage> */}

                                            </div>


                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 10 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(9) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(11) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture10} titleString={"10. ESZSZF"}
                                                    description={<div>Tudomásul veszem, hogy amennyiben a Szolgáltatótól az előfizetői szolgáltatások körébe nem tartozó Egyéb Szolgáltatást is igénybe veszek, arra az Egyéb Szolgáltatások Szerződési Feltételei (ESZSZF) az irányadóak és nyilatkozom, hogy az Egyéb Szolgáltatások Szerződési Feltételeit megismertem és elfogadom. Az ESZSZF a Szolgáltató honlapján a https://www.one.hu/aszf linken érhető el.</div>}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 11 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(10) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(12) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage videoUrl={video11} titleString={"11. Hatályos szerződés"}
                                                    description={"Nyilatkozom, hogy az Előfizetői Szerződés megkötése előtt megkaptam a Szolgáltatótól a tájékoztatást arról, hogy az Előfizetői Szerződés fennállása alatt, vagy annak megszűnését követően az Eht. 143. § (2) bekezdése szerinti elévülési időn belül évente egyszer ingyenesen kérhetem az Előfizetői Szerződés általam megjelölt időpontjában hatályos tartalmáról szóló, legalább az Eszr. 11. § (1) bekezdés szerinti elemeket tartalmazó dokumentum átadását."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 12 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(11) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(13) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture12} titleString={"12. KEKKH"}
                                                    description={"Tudomásul veszem hogy a megadott személyes adataimat a One, a rá vonatkozó jogi kötelezettség teljesítéséhez szükséges mértékben az adategyeztetéshez kezelje, azok valódiságát a Belügyminisztérium adatbázisában elektronikusan ellenőrizze, és megadott elérhetőségeimen az adategyeztetéssel kapcsolatosan elektronikus formában (SMS-ben, e-mailen) megkeressen. Tudomásul veszem, hogy a One Magyarország Zrt. 1519 Budapest, Pf.: 543. postai, DPO@one.hu és az ugyfelszolgalat@one.hu elektronikus címeken, a 1270-es vagy a +36-1-288-1270-es telefonszámon, valamint személyesen, a One üzleteben élhetek az érintetti jogaimmal, ide értve a nyilvántartott személyes adataimhoz való hozzáférést, azok törlését, helyesbítését, illetőleg kérhetek a kezelt adatokról való tájékoztatást."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 13 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(12) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(14) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                {/* description={<div>Nyilatkozom, hogy az egyedi Előfizetői Szerződésben foglaltakat – különösen az abban foglalt hivatkozásokat – megismertem és tudomásul veszem. Tudomásul veszem, hogy az Előfizetői Szerződés az egyedi előfizetői szerződésből és az egyedi előfizetői szerződés tárgyát képező szolgáltatásra vonatkozó Általános Szerződési Feltételekből (ÁSZF) áll, illetve az Előfizetői Szerződés megkötésekor annak részévé válik az Előfizetői Szerződés megkötését megelőzően a rendelkezésemre bocsátott előzetes tájékoztatás és a szerződés adatainak az összefoglalója is. A szolgáltatással kapcsolatos kérdésekben elsősorban az egyedi előfizetői rendelkezések, másodsorban az ÁSZF rendelkezései az irányadóak. Nyilatkozom, hogy az <a href="https://www.vodafone.hu/vodafonerol/aszf" target="_blank" style={{ backgroundColor: 'yellow', color: 'rgb(167, 169, 192)', fontSize: this.stores.InfoVideoStore.RootStore.isMobile ? '14px' : '17px' }}>Általános Szerződési Feltételeket (ÁSZF)</a> megismertem és elfogadom.</div>}> */}
                                                <InfoVideoDetailsPage videoUrl={video13} titleString={"13. Mindenkori ÁSZF"}
                                                    description={<div>Nyilatkozom, hogy az egyedi Előfizetői Szerződésben foglaltakat – különösen az abban foglalt hivatkozásokat – megismertem és tudomásul veszem. Tudomásul veszem, hogy az Előfizetői Szerződés az egyedi előfizetői szerződésből és az egyedi előfizetői szerződés tárgyát képező szolgáltatásra vonatkozó Általános Szerződési Feltételekből (ÁSZF) áll, illetve az Előfizetői Szerződés megkötésekor annak részévé válik az Előfizetői Szerződés megkötését megelőzően a rendelkezésemre bocsátott előzetes tájékoztatás és a szerződés adatainak az összefoglalója is. A szolgáltatással kapcsolatos kérdésekben elsősorban az egyedi előfizetői rendelkezések, másodsorban az ÁSZF rendelkezései az irányadóak. Nyilatkozom, hogy az <a href="https://www.one.hu/aszf" target="_blank" style={{ backgroundColor: 'yellow', color: 'rgb(167, 169, 192)', fontSize: this.stores.InfoVideoStore.RootStore.isMobile ? '14px' : '17px' }}>Általános Szerződési Feltételeket (ÁSZF)</a> megismertem és elfogadom.</div>}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {/* {this.stores.InfoVideoStore.currentNumber === 14 && (
                                            <InfoVideoDetailsPage pictureUrl={picture14} titleString={"14. Nyilatkozat inflációkövető áremelés elfogadásáról"}
                                                description={"Az Előfizetői Szerződésem megkötésével kifejezetten hozzájárulok és elfogadom a Felek közös megegyezésével megvalósuló azon jövőbeli szerződésmódosítást, amely alapján amennyiben az éves fogyasztói árindex (infláció) 1 %-ot elérő, vagy azt meghaladó mértékű növekedése következik be a megelőző tárgyévhez képest, abban az esetben az Előfizetői Szerződésemben foglalt díjak az infláció mértékével automatikusan módosításra kerülnek."}>
                                                <div style={{ display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(13) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(15) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                            </InfoVideoDetailsPage>
                                        )} */}
                                        {this.stores.InfoVideoStore.currentNumber === 14 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(13) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(15) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture15} titleString={"14. Nyilatkozat jogképességről"}
                                                    description={"Előfizetőként ezúton büntetőjogi felelősségem tudatában kijelentem, hogy sem szerződéskötési képességemben, sem pedig szerződéskötési jogosultságomban nem vagyok korlátozva, az Egyedi Előfizetői Szerződés megkötésének akadálya nincsen, továbbá az általam az Előfizetői Szerződésben megadott adatok és nyilatkozatok a valóságnak megfelelnek, megértettem és elfogadom, hogy a megadott adatok valódiságával kapcsolatos nyilatkozatomat az Előfizetői Szerződés hatálya és a jogérvényesítésre nyitva álló határidő alatt nem vonhatom vissza."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 15 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(14) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(16) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture8}  titleString={"15. Roaming"}
                                                    description={"Nyilatkozom, hogy az Előfizetői Szerződés megkötése során az előzetes tájékoztatásban a Szolgáltató teljes körű tájékoztatást nyújtott az érvényes barangolási díjakról és a szabályozott barangolásos adatátviteli szolgáltatások díjazásáról, illetve az alternatív barangolásszolgáltató választásának lehetőségéről."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 16 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(15) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(17) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture17} titleString={"16. Szerződés tartalma"}
                                                    description={"Nyilatkozom, hogy az Előfizetői Szerződés megkötése során az előzetes tájékoztatás és az előfizetői szerződés adatainak összefoglalója számomra a Szolgáltató által megküldésre került és azok tartalma számomra ismert volt."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 17 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(16) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(18) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage videoUrl={video18} titleString={"17. Szolgáltatás minősége"}
                                                    description={"Tájékoztatást megkaptam az előfizetői szerződésben megjelölt igénybevételi helyen a szolgáltatás minőségének hiányosságaival kapcsolatosan gyakorolható felmondási jog lehetőségéről, feltételeiről és következményeiről az az alábbiak szerint: Amennyiben az előfizetői szerződésben megjelölt hozzáférési ponton vagy cím, helyrajzi szám szerint megjelölt igénybevételi helyen a szolgáltatás minősége - a szolgáltatás sebessége vagy más szolgáltatásminőségi paraméter tekintetében az internet-hozzáférési szolgáltatás tényleges teljesítménye folyamatosan, vagy rendszeresen ismétlődő jelleggel - nem felel meg az előfizetői szerződésben foglaltaknak vagy nem vehető igénybe, illetve, ha az internetszolgáltatás nem teszi lehetővé, hogy az előfizetői szerződése részeként nyújtott digitális tartalmat vagy alkalmazást használjam, abban az esetben a határozott idejű előfizetői szerződésem a szolgáltatás nyújtásának megkezdésétől számított 14 napon belül azonnali hatállyal felmondhatom. A fenti felmondás esetén a Szolgáltató csak a szerződéskötéstől vagy a szerződés hatályba lépésétől a felmondásig eltelt időre járó arányos havidíjat, valamint forgalomarányos díjat tartalmazó Szolgáltatás esetében az általam ténylegesen felhasznált forgalom díját, vagy a Szolgáltatással igénybe vett eseti díjas egyéb Szolgáltatások ellenértékét követelheti. A Szolgáltató egyéb költséget, így különösen felmondási vagy adminisztrációs díjat nem számíthat fel. Megjelölt igénybevételi hely: Az Előfizetői Szerződés „Előfizető adatai” pontjában, illetve a „Szolgáltató adatai” pontjában megadott cím."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 18 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(17) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Igen" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(19) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture18} titleString={"18. ÁSZF-igény"}
                                                    description={"Nyilatkozom, hogy az ÁSZF teljes szövegének a rendelkezésemre bocsátására elektronikus úton (e-mail) igényt tartok."}>
                                                    <div>

                                                    </div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {/* {this.stores.InfoVideoStore.currentNumber === 19 && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(18) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(20) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture20} titleString={"19. Kétoldalú módosítás"}
                                                    description={"Kijelentem, hogy az Előfizetői Szerződés ideje alatt szóban úgy nyilatkoztam, hogy amennyiben a Szolgáltató az Előfizetői Szerződés módosítását kezdeményezi, a nyilatkozattételem elmulasztása, mint ráutaló magatartás, vagy a tevőleges magatartásom a szerződés módosítás elfogadásának minősül."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )} */}
                                        {this.stores.InfoVideoStore.currentNumber === 19 && !this.stores.InfoVideoStore.haswatermarkt && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(18) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Elfogadom" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem fogadom el" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(20) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage videoUrl={video21} titleString={"19. Egyedi értékhatár"}
                                                    description={"Minden Utólag Fizető Előfizető részére a Szolgáltató egyedileg, külön-külön megállapított, rendszeres időközönként felülvizsgált összeget (továbbiakban: Egyedi Értékhatár) állapít meg. Az Egyedi Értékhatár számítása a mobil-rádiótelefon, a vezeték nélküli internet és a helyhez kötött Internet hozzáférés szolgáltatás esetében is azonos. Az Egyedi Értékhatár 30%-70% arányban megoszlik mobilvásárlás értékhatárra (a továbbiakban MCOM értékhatár) és egyéb telekommunikációs értékhatárra (a továbbiakban Telco értékhatár), mely aránytól a Felek közös megállapodással eltérhetnek az Egyedi Eőlfizetői Szerződésben. Az Egyedi Értékhatárra vonatkozó rendelkezések teremtik meg az alapját az Eht. 137. § (1) bekezdés d) pontja szerinti korlátozásnak. Az MCOM értékhatár és/vagy Telco értékhatár elérése esetén a Szolgáltató által küldött ingyenes értesítés módja: az Egyedi Értékhatár 80%-nak elérésekor SMS értesítés, majd az Egyedi Értékhatár átlépését követően SMS vagy hívás formájában értesíti az előfizető által megjelölt hivatalos kapcsolattartót."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 20 && !this.stores.InfoVideoStore.haswatermarkt && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(19) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Igen" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(21) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                <InfoVideoDetailsPage pictureUrl={picture21} titleString={"20. Személyes adatok módosítása"}
                                                    description={"Büntetőjogi felelősségem tudatában kijelentem, hogy az általam megadott adatok a valóságnak megfelelnek. Hozzájárulok, hogy amennyiben az itt megadott, és sikeresen ellenőrzött személyes adataim, és a One által kezelt személyes adataim között eltérés van, a megadott adatok alapján a One pontosítsa szerződésmódosítással az általa kezelt adataimat, amely az Eht. 132. § (6) bekezdés b) pontja szerinti, adminisztratív jellegű módosításnak minősül, amelyre tekintettel a felmondás joga nem illet meg."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {this.stores.InfoVideoStore.currentNumber === 21 && this.stores.InfoVideoStore.needTwentyTwo && !this.stores.InfoVideoStore.haswatermarkt && (
                                            <div>
                                                <div style={{ marginBottom: '20px', display: this.stores.InfoVideoStore.RootStore.isMobile ? 'block' : 'flex', width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 100, }}>
                                                    <div style={{ marginRight: 30 }}>
                                                        <Button onClick={() => { this.stores.InfoVideoStore.previousPage(20) }}>Vissza</Button>
                                                    </div>

                                                    <div style={{ marginRight: 10, paddingTop: 10 }}>
                                                        <Checkbox label="Kérem" checked={this.stores.InfoVideoStore.getAnswerValue == 1} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(data.checked) }} radio></Checkbox>

                                                    </div>
                                                    <div style={{ marginRight: 30, paddingTop: 10 }}>
                                                        <Checkbox label="Nem kérem" checked={this.stores.InfoVideoStore.getAnswerValue == 2} onChange={(event, data) => { this.stores.InfoVideoStore.updateAnswerValue(!data.checked) }} radio></Checkbox>
                                                    </div>
                                                    <div>
                                                        <Button color='green' onClick={() => { this.stores.InfoVideoStore.nextPage(22) }} disabled={this.stores.InfoVideoStore.isDisable}>Tovább</Button>
                                                    </div>
                                                </div>
                                                {/* <div style={{ marginBottom: '20px', fontWeight: 'bold', textAlign: 'center' }}>
                                                    Tisztelt Ügyfelünk! <span style={{ color: 'red' }}>Az alábbi nyilatkozat csak Maxi+ tarifacsomag esetén érvényes.</span> Kérdés esetén kérjük forduljon munkatársunkhoz.

                                                </div> */}
                                                <InfoVideoDetailsPage pictureUrl={picture17} titleString={"21. Korlátozott szolgáltatási sebességű video opció"}

                                                    description={"Előfizetőként kérem, hogy a megrendelt szolgáltatáscsomagnál a video tartalmakhoz való hozzáférést a Szolgáltató 1080p felbontásban korlátozott minőségben tegye elérhetővé. Ezen korlátozással az Előfizető a hálózati leterheltség függvényében optimalizált hálózati kapacitáshoz jut, amely elősegíti számára a videótartalmak zavartalan használatát. Az Előfizetőnek a korlátozott szolgáltatási sebességű video opció (1080p videó felbontás) igénylésére és lemondására az Előfizetői Szerződés megkötése során, vagy a Szolgáltató személyes és telefonos Ügyfélszolgálatán van lehetősége."}>
                                                    <div></div>
                                                </InfoVideoDetailsPage>
                                            </div>

                                        )}
                                        {(this.stores.InfoVideoStore.currentNumber === 22 || this.stores.InfoVideoStore.haswatermarkt) && this.stores.InfoVideoStore.needTwentyTwo && (
                                            <InfoVideoDetailsPage>
                                                <div style={{ width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 110 : 240 }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Icon size='big' color="green" name="check circle outline"></Icon>
                                                    </div>
                                                    <div style={{ fontSize: this.stores.InfoVideoStore.RootStore.isMobile ? '14px' : '26px', fontWeight: 'bold', textAlign: 'center', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 10 : 30, width: this.stores.InfoVideoStore.RootStore.isMobile ? '50%' : '100%', marginLeft: this.stores.InfoVideoStore.RootStore.isMobile ? 'auto' : 0, marginRight: this.stores.InfoVideoStore.RootStore.isMobile ? 'auto' : 0 }}>
                                                        Minden nyilatkozatát rögzítettük! Köszönjük!
                                                    </div>

                                                    {!this.stores.InfoVideoStore.closed && !this.stores.InfoVideoStore.haswatermarkt &&
                                                        <div style={{ textAlign: 'center', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 10 : 30 }}>
                                                            <Button disabled={this.stores.InfoVideoStore.isCloseInfovideoPressed} size={this.stores.InfoVideoStore.RootStore.isMobile ? 'small' : 'huge'} onClick={() => { this.stores.InfoVideoStore.closeInfoVideo() }} color='green'>Küldés</Button>
                                                        </div>
                                                    }
                                                    {this.stores.InfoVideoStore.haswatermarkt &&
                                                        <div style={{ textAlign: 'center', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 10 : 30 }}>
                                                            <Button disabled={this.stores.InfoVideoStore.isCloseInfovideoPressed} size={this.stores.InfoVideoStore.RootStore.isMobile ? 'small' : 'huge'} onClick={() => { this.stores.InfoVideoStore.getWaterMarkedVersion() }} color='green'>Szerződés megtekintése</Button>
                                                        </div>
                                                    }

                                                </div>
                                            </InfoVideoDetailsPage>
                                        )}
                                        {(this.stores.InfoVideoStore.currentNumber === 21 || this.stores.InfoVideoStore.haswatermarkt) && !this.stores.InfoVideoStore.needTwentyTwo && (
                                            <InfoVideoDetailsPage>
                                                <div style={{ width: 'max-content', marginLeft: 'auto', marginRight: 'auto', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 110 : 240 }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Icon size='big' color="green" name="check circle outline"></Icon>
                                                    </div>
                                                    <div style={{ fontSize: this.stores.InfoVideoStore.RootStore.isMobile ? '14px' : '26px', fontWeight: 'bold', textAlign: 'center', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 10 : 30, width: this.stores.InfoVideoStore.RootStore.isMobile ? '50%' : '100%', marginLeft: this.stores.InfoVideoStore.RootStore.isMobile ? 'auto' : 0, marginRight: this.stores.InfoVideoStore.RootStore.isMobile ? 'auto' : 0 }}>
                                                        Minden nyilatkozatát rögzítettük! Köszönjük!
                                                    </div>

                                                    {!this.stores.InfoVideoStore.closed && !this.stores.InfoVideoStore.haswatermarkt &&
                                                        <div style={{ textAlign: 'center', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 10 : 30 }}>
                                                            <Button disabled={this.stores.InfoVideoStore.isCloseInfovideoPressed} size={this.stores.InfoVideoStore.RootStore.isMobile ? 'small' : 'huge'} onClick={() => { this.stores.InfoVideoStore.closeInfoVideo() }} color='green'>Küldés</Button>
                                                        </div>
                                                    }
                                                    {this.stores.InfoVideoStore.haswatermarkt &&
                                                        <div style={{ textAlign: 'center', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 10 : 30 }}>
                                                            <Button disabled={this.stores.InfoVideoStore.isCloseInfovideoPressed} size={this.stores.InfoVideoStore.RootStore.isMobile ? 'small' : 'huge'} onClick={() => { this.stores.InfoVideoStore.getWaterMarkedVersion() }} color='green'>Szerződés megtekintése</Button>
                                                        </div>
                                                    }

                                                </div>
                                            </InfoVideoDetailsPage>
                                        )}

                                        {this.stores.InfoVideoStore.requiredAlert &&
                                            <div style={{ marginLeft: "auto", marginRight: "auto", height: this.stores.InfoVideoStore.RootStore.isMobile ? '97px' : '67px', color: 'red', border: '1px solid red', padding: '20px', textAlign: 'center', marginTop: '20px' }}>
                                                Figyelem, a nyilatkozat visszautasítása esetén a szerződés kötése nem folytatható!
                                            </div>}

                                        {this.stores.InfoVideoStore.currentNumber < 22 && (
                                            <div style={{ textAlign: 'center', marginTop: 40 }}>
                                                <Button color='red' onClick={() => { this.stores.InfoVideoStore.setCancelInfoVideo() }}>Megszakítás</Button>
                                            </div>
                                        )}
                                        <Confirm open={this.stores.InfoVideoStore.isInfoVideoCancelledPopup}
                                            closeOnEscape={false} //disables closing the window by pressing ESC	
                                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
                                            onConfirm={(event) => { this.stores.InfoVideoStore.cancelInfoVideoPopup(event); }}
                                            onCancel={(event) => { this.stores.InfoVideoStore.cancelInfoVideoPopup(event); }}
                                            cancelButton="Mégse"
                                            confirmButton="Megszakítás"
                                            content={
                                                <div style={{ padding: 30 }}>
                                                    <p>
                                                        Az ajánlat elutasításáról értesítenie kell a szaktanácsadót!
                                                    </p>
                                                </div>
                                            }
                                        ></Confirm>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    }
                </Provider>
            </div>
        )
    }
}

export default inject('RootStore')(observer(InfoVideoPage));
