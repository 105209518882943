import { action, computed, flow, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import { NavigateFunction } from "react-router-dom";
import { ContentItems, InfoVideoAnswer, InfoVideoResponse } from '../../models/infovideoAnswer';
import { InfoVideoService } from '../../services/infovideo.service'
import { RootStore } from "../../stores/RootStore";
export class InfoVideoStore {

    navigate?: NavigateFunction;
    contentItems: Array<ContentItems> = [];
    currentNumber: number = 0;
    isInfoVideoCancelledPopup: boolean = false;
    offerId: number;
    answers: InfoVideoAnswer[];
    InfoVideoService: InfoVideoService;
    loadingCount: number = 0;
    loadings: string[] = [];
    RootStore: RootStore;
    isCloseInfovideoPressed: boolean = false;

    offerExist: boolean = false;
    needTwentyTwo: boolean = false;
    closed: boolean = false;
    haswatermarkt: boolean = false;
    requiredAlert: boolean = false;

    constructor(offerId: number, RootStore: RootStore) {
        this.offerId = offerId;
        this.RootStore = RootStore;
        makeAutoObservable(this, {
            contentItems: observable,
            currentNumber: observable,
            isInfoVideoCancelledPopup: observable,
            offerId: observable,
            answers: observable,
            offerExist: observable,
            loadingCount: observable,
            loadings: observable,
            requiredAlert: observable,
            isCloseInfovideoPressed: observable,
            setContentItemActive: action,
            setCancelInfoVideo: action,
            cancelInfoVideoPopup: action,
            getAnswerValue: computed,
            updateAnswerValue: action,
            nextPage: flow,
            previousPage: flow,
            getAnswers: flow,
            saveAnswer: flow,
            closeInfoVideo: flow,
            isDisable: computed,
            haswatermarkt: observable,
            hasWaterMarkedVersion: flow

        })

        this.InfoVideoService = new InfoVideoService();

        this.getAnswers();
        this.hasWaterMarkedVersion();
    }

    setContentItemActive = (index: number) => {
        for (let i = 0; i < this.contentItems.length; i++) {
            this.contentItems[i].isActive = false;
        }
        this.contentItems[index].isActive = true;
        this.currentNumber = this.contentItems[index].key
    }

    setCancelInfoVideo = () => {
        this.isInfoVideoCancelledPopup = true;
    }

    cancelInfoVideoPopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isInfoVideoCancelledPopup = false;
    }
    *hasWaterMarkedVersion() {
        this.haswatermarkt = yield this.InfoVideoService.hasWaterMarkedVersion(this.offerId);
        if (this.haswatermarkt) {
            this.currentNumber = 21;
        }
        console.log('watermarktversion: ' + this.haswatermarkt)
        let debug = 1;
    }
    *nextPage(nextPageNumber: number) {

        this.requiredAlert = false;
        // if (!this.contentItems[nextPageNumber].answer.Answer) {
        //     this.contentItems[nextPageNumber].answer.Answer = 1;
        // }
        if (this.contentItems[this.currentNumber].isRequired) {
            if (!this.contentItems[this.currentNumber].answer.Answer || this.contentItems[this.currentNumber].answer.Answer != 1) {
                //   alert("Figyelem, a nyilatkozat visszautasítása esetén a szerződés kötése nem folytatható!")
                this.requiredAlert = true;
                return;
            }
        }

        yield this.saveAnswer(this.contentItems[this.currentNumber].answer);

        this.currentNumber = nextPageNumber;
    }

    *previousPage(prevPageNumber: number) {
        this.currentNumber = prevPageNumber;
    }

    addloading = (loading: string) => {
        console.log("ADD: " + loading);
        this.loadings.push(loading);
        this.loadingCount++;
    }
    removeLoading = (loading: string) => {
        console.log("Remove: " + loading);
        this.removeFromArray(this.loadings, loading);
        this.loadingCount--;
    }
    removeFromArray = (array: any[], element: any) => {
        var index = array.indexOf(element);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    *saveAnswer(answer: InfoVideoAnswer) {
        this.RootStore.addloading("saveAnswer");
        if (this.offerId) {
            let responseId: number = yield this.InfoVideoService.SaveInfoVideoAnswer(answer);

            if (responseId) {
                answer.Id = responseId;
            }
        }

        this.RootStore.removeLoading("saveAnswer");
    }
    *getWaterMarkedVersion() {
        this.RootStore.addloading("getWaterMarkedVersion");
        if (this.offerId) {
            yield this.InfoVideoService.getWaterMarkedVersion(this.offerId);


        }

        this.RootStore.removeLoading("getWaterMarkedVersion");
    }
    *closeInfoVideo() {
        this.isCloseInfovideoPressed = true;
        this.RootStore.addloading("closeInfoVideo");
        if (this.offerId) {
            yield this.InfoVideoService.CloseInfoVideoAnswer(this.offerId);


            window.location.href = "/";
            // window.location.reload();
            //this.navigate(`/`)
        }

        this.RootStore.removeLoading("closeInfoVideo");
    }


    get getAnswerValue(): number {
        return this.contentItems[this.currentNumber].answer.Answer;
    }
    updateAnswerValue(value: boolean) {
        this.requiredAlert = false;
        if (this.contentItems[this.currentNumber].isRequired && !value) {
            this.requiredAlert = true;
        }
        this.contentItems[this.currentNumber].answer.Answer = value ? 1 : 2;
    }

    *getAnswers() {

        this.RootStore.addloading("getAnswers");
        if (this.offerId) {
            let response: InfoVideoResponse = yield this.InfoVideoService.GetInfoVideoByOfferId(this.offerId);

            if (!response) {
                this.offerExist = false;
            }
            else {
                this.offerExist = response.OfferExist;
                this.closed = response.Closed;
                this.needTwentyTwo = response.NeedTwentyTwo
                this.answers = response.Answers;

                if (!response.Closed) {
                    let steps = response.Answers.map(a => { return a.Step; });

                    if (steps.length > 0) {
                        this.currentNumber = Math.max(...steps);
                    }
                }
                else {
                    this.currentNumber = 20;
                }
            }
        }

        this.setContentItems();
        this.RootStore.removeLoading("getAnswers");
    }

    setContentItems() {
        if (this.needTwentyTwo) {
            this.contentItems = [
                { key: 0, isRequired: false, title: "Infovideó, mint digitális segítség", checked: false, answer: new InfoVideoAnswer(0, this.offerId), isActive: true },
                { key: 1, isRequired: true, title: "1. E-számla", checked: false, answer: new InfoVideoAnswer(1, this.offerId), isActive: true },
                { key: 2, isRequired: false, title: "2. Geo-lokáció", checked: false, answer: new InfoVideoAnswer(2, this.offerId), isActive: true },
                { key: 3, isRequired: false, title: "3. Marketing", checked: false, answer: new InfoVideoAnswer(3, this.offerId), isActive: true },
                { key: 4, isRequired: true, title: "4. Adatkezelési tájékoztató", checked: false, answer: new InfoVideoAnswer(4, this.offerId), isActive: true },
                { key: 5, isRequired: true, title: "5. E-számla Post", checked: false, answer: new InfoVideoAnswer(5, this.offerId), isActive: true },
                { key: 6, isRequired: true, title: "6. Egyéni előfizetői minőséggel kapcsolatos nyilatkozat", checked: false, answer: new InfoVideoAnswer(6, this.offerId), isActive: true },
                { key: 7, isRequired: true, title: "7. Elállás / azonnali hatályú felmondás", checked: false, answer: new InfoVideoAnswer(7, this.offerId), isActive: true },
                { key: 8, isRequired: true, title: "8. Elektronikus értesítés és elektronikus szerződés", checked: false, answer: new InfoVideoAnswer(8, this.offerId), isActive: true },
                { key: 9, isRequired: false, title: "9. Tudakozó", checked: false, answer: new InfoVideoAnswer(9, this.offerId), isActive: true },
                { key: 10, isRequired: true, title: "10. ESZSZF", checked: false, answer: new InfoVideoAnswer(10, this.offerId), isActive: true },
                { key: 11, isRequired: true, title: "11. Hatályos szerződés", checked: false, answer: new InfoVideoAnswer(11, this.offerId), isActive: true },
                { key: 12, isRequired: true, title: "12. KEKKH", checked: false, answer: new InfoVideoAnswer(12, this.offerId), isActive: true },
                { key: 13, isRequired: true, title: "13. Mindenkori ÁSZF", checked: false, answer: new InfoVideoAnswer(13, this.offerId), isActive: true },
                // { key: 14, isRequired: true, title: "14. Nyilatkozat inflációkövető áremelés elfogadásáról", checked: false, answer: new InfoVideoAnswer(14, this.offerId), isActive: true },
                { key: 14, isRequired: true, title: "14. Nyilatkozat jogképességről", checked: false, answer: new InfoVideoAnswer(14, this.offerId), isActive: true },
                { key: 15, isRequired: true, title: "15. Roaming", checked: false, answer: new InfoVideoAnswer(15, this.offerId), isActive: true },
                { key: 16, isRequired: true, title: "16. Szerződés tartalma", checked: false, answer: new InfoVideoAnswer(16, this.offerId), isActive: true },
                { key: 17, isRequired: true, title: "17. Szolgáltatás minősége", checked: false, answer: new InfoVideoAnswer(17, this.offerId), isActive: true },
                { key: 18, isRequired: false, title: "18. ÁSZF-igény", checked: false, answer: new InfoVideoAnswer(18, this.offerId), isActive: true },
                // { key: 19, isRequired: true, title: "19. Kétoldalú módosítás", checked: false, answer: new InfoVideoAnswer(19, this.offerId), isActive: true },
                { key: 19, isRequired: true, title: "19. Egyedi értékhatár", checked: false, answer: new InfoVideoAnswer(19, this.offerId), isActive: true },
                { key: 20, isRequired: true, title: "20. Személyes adatok módosítása", checked: false, answer: new InfoVideoAnswer(20, this.offerId), isActive: true },
                { key: 21, isRequired: false, title: "21. Korlátozott szolgáltatási sebességű video opció", checked: false, answer: new InfoVideoAnswer(21, this.offerId), isActive: true },
            ];
        }
        if (!this.needTwentyTwo) {
            this.contentItems = [
                { key: 0, isRequired: false, title: "Infovideó, mint digitális segítség", checked: false, answer: new InfoVideoAnswer(0, this.offerId), isActive: true },
                { key: 1, isRequired: true, title: "1. E-számla", checked: false, answer: new InfoVideoAnswer(1, this.offerId), isActive: true },
                { key: 2, isRequired: false, title: "2. Geo-lokáció", checked: false, answer: new InfoVideoAnswer(2, this.offerId), isActive: true },
                { key: 3, isRequired: false, title: "3. Marketing", checked: false, answer: new InfoVideoAnswer(3, this.offerId), isActive: true },
                { key: 4, isRequired: true, title: "4. Adatkezelési tájékoztató", checked: false, answer: new InfoVideoAnswer(4, this.offerId), isActive: true },
                { key: 5, isRequired: true, title: "5. E-számla Post", checked: false, answer: new InfoVideoAnswer(5, this.offerId), isActive: true },
                { key: 6, isRequired: true, title: "6. Egyéni előfizetői minőséggel kapcsolatos nyilatkozat", checked: false, answer: new InfoVideoAnswer(6, this.offerId), isActive: true },
                { key: 7, isRequired: true, title: "7. Elállás / azonnali hatályú felmondás", checked: false, answer: new InfoVideoAnswer(7, this.offerId), isActive: true },
                { key: 8, isRequired: true, title: "8. Elektronikus értesítés és elektronikus szerződés", checked: false, answer: new InfoVideoAnswer(8, this.offerId), isActive: true },
                { key: 9, isRequired: false, title: "9. Tudakozó", checked: false, answer: new InfoVideoAnswer(9, this.offerId), isActive: true },
                { key: 10, isRequired: true, title: "10. ESZSZF", checked: false, answer: new InfoVideoAnswer(10, this.offerId), isActive: true },
                { key: 11, isRequired: true, title: "11. Hatályos szerződés", checked: false, answer: new InfoVideoAnswer(11, this.offerId), isActive: true },
                { key: 12, isRequired: true, title: "12. KEKKH", checked: false, answer: new InfoVideoAnswer(12, this.offerId), isActive: true },
                { key: 13, isRequired: true, title: "13. Mindenkori ÁSZF", checked: false, answer: new InfoVideoAnswer(13, this.offerId), isActive: true },
                // { key: 14, isRequired: true, title: "14. Nyilatkozat inflációkövető áremelés elfogadásáról", checked: false, answer: new InfoVideoAnswer(14, this.offerId), isActive: true },
                { key: 14, isRequired: true, title: "14. Nyilatkozat jogképességről", checked: false, answer: new InfoVideoAnswer(14, this.offerId), isActive: true },
                { key: 15, isRequired: true, title: "15. Roaming", checked: false, answer: new InfoVideoAnswer(15, this.offerId), isActive: true },
                { key: 16, isRequired: true, title: "16. Szerződés tartalma", checked: false, answer: new InfoVideoAnswer(16, this.offerId), isActive: true },
                { key: 17, isRequired: true, title: "17. Szolgáltatás minősége", checked: false, answer: new InfoVideoAnswer(17, this.offerId), isActive: true },
                { key: 18, isRequired: false, title: "18. ÁSZF-igény", checked: false, answer: new InfoVideoAnswer(18, this.offerId), isActive: true },
                // { key: 19, isRequired: true, title: "19. Kétoldalú módosítás", checked: false, answer: new InfoVideoAnswer(19, this.offerId), isActive: true },
                { key: 19, isRequired: true, title: "19. Egyedi értékhatár", checked: false, answer: new InfoVideoAnswer(19, this.offerId), isActive: true },
                { key: 20, isRequired: true, title: "20. Személyes adatok módosítása", checked: false, answer: new InfoVideoAnswer(20, this.offerId), isActive: true },
            ];
        }
        this.answers.forEach((answer) => {
            let contentItem = this.contentItems.find(it => it.key == answer.Step);

            if (contentItem) {
                contentItem.answer = answer;
            }

        })
    }

    get isDisable() {
        if (!this.contentItems[this.currentNumber].answer.Answer) {
            return true;
        }
        return false;
    }

}